import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../logbook.reducer';
import * as AppActions from '../../app/actions';
import { HttpParams } from '@angular/common/http';
import { IGetManyResponse, IGetOneResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { catchError, switchMap, of } from 'rxjs';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import { FormTasksService } from '../../../shared/service/my-tasks/form-tasks/form-tasks.service';
import * as ObjectActions from './form-tasks.actions';
import { IFormVersion, IFormTasksData, IFieldSetData } from './form-tasks.model';
import { IFormTasksHistory } from '../../../view/my-tasks/form-tasks/form-tasks.component.model';
import { IReviewResults } from '../my-tasks.model';
import { IWorkflow } from '../../settings/workflows/workflows.model';
import { WorkflowsService } from '../../settings/workflows/workflows.service';

@Injectable()
export class FormTasksEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppState>,
    private readonly service: FormTasksService,
    private readonly workflowService: WorkflowsService,
  ) {}

  getReviewData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_FORM_TASKS_LOADING),
      switchMap((payload: ObjectActions.StartFormTasksLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const body: Record<string, string | number> = ServiceUtilities.prepareGenericBodyForRequest(payload.tableQuery);

        return this.service.getReviewData(body, payload.reviewType).pipe(
          switchMap((response: IGetManyResponse<IFormTasksData>) => {
            return of(new ObjectActions.FormTasksLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormTasksBeforeRecord = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_FORM_TASKS_BEFORE_RECORD_LOADING),
      switchMap((payload: ObjectActions.StartFormTasksBeforeRecordLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getFormTasksActiveRecord(payload.formId).pipe(
          switchMap((response: IGetOneResponse<IFormVersion>) => {
            return of(new ObjectActions.FormTasksBeforeRecordLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormTasksAfterRecord = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_FORM_TASKS_AFTER_RECORD_LOADING),
      switchMap((payload: ObjectActions.StartFormTasksAfterRecordLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getFormTasksRecord(payload.formId, payload.id).pipe(
          switchMap((response: IGetOneResponse<IFormVersion>) => {
            return of(new ObjectActions.FormTasksAfterRecordLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormTasksBeforeFieldSet = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_FORM_TASKS_BEFORE_FIELD_SET_LOADING),
      switchMap((payload: ObjectActions.StartFormTasksBeforeFieldSetLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getFormTasksFieldSet(payload.fieldSetVersionId).pipe(
          switchMap((response: IGetOneResponse<IFieldSetData[]>) => {
            return of(new ObjectActions.FormTasksBeforeFieldSetLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormTasksAfterFieldSet = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_FORM_TASKS_AFTER_FIELD_SET_LOADING),
      switchMap((payload: ObjectActions.StartFormTasksAfterFieldSetLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getFormTasksFieldSet(payload.fieldSetVersionId).pipe(
          switchMap((response: IGetOneResponse<IFieldSetData[]>) => {
            return of(new ObjectActions.FormTasksAfterFieldSetLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormTasksHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_FORM_TASKS_HISTORY_LOADING),
      switchMap((payload: ObjectActions.StartFormTasksHistoryLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getFormTasksHistoryForOneVersion(payload.id, payload.reviewType).pipe(
          switchMap((response: IGetManyResponse<IFormTasksHistory>) => {
            return of(new ObjectActions.FormTasksHistoryLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormTasksActiveVersionHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_FORM_TASKS_ACTIVE_VERSION_HISTORY_LOADING),
      switchMap((payload: ObjectActions.StartFormTasksHistoryLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getFormTasksHistory(payload.id, true, payload.reviewType).pipe(
          switchMap((response: IGetManyResponse<IFormTasksHistory>) => {
            return of(new ObjectActions.FormTasksActiveVersionHistoryLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormTasksCurrentVersionHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_FORM_TASKS_CURRENT_VERSION_HISTORY_LOADING),
      switchMap((payload: ObjectActions.StartFormTasksHistoryLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getFormTasksHistory(payload.id, false, payload.reviewType).pipe(
          switchMap((response: IGetManyResponse<IFormTasksHistory>) => {
            return of(new ObjectActions.FormTasksCurrentVersionHistoryLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  reviewFormEntries = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_ENTRY_APPROVE_LOADING),
      switchMap((payload: ObjectActions.StartEntryApproveLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.reviewEntries(payload.reviewPayload, payload.issuer, payload.reviewType).pipe(
          switchMap((response: IGetOneResponse<IReviewResults[]>) => {
            return of(new ObjectActions.EntryApproveLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  rejectEntries = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_ENTRY_REJECT_LOADING),
      switchMap((payload: ObjectActions.StartEntryRejectLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.reviewEntries(payload.reviewPayload, payload.issuer, payload.reviewType).pipe(
          switchMap((response: IGetOneResponse<IReviewResults[]>) => {
            return of(new ObjectActions.EntryRejectLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  rejectPermanentEntries = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_ENTRY_REJECT_PERMANENT_LOADING),
      switchMap((payload: ObjectActions.StartEntryRejectPermanentLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.reviewEntries(payload.reviewPayload, payload.issuer, payload.reviewType).pipe(
          switchMap((response: IGetOneResponse<IReviewResults[]>) => {
            return of(new ObjectActions.EntryRejectPermanentLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getWorkflowData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_WORKFLOW_DATA_LOADING),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams().append('limit', 1000).append('just_workflow_data', true);

        return this.workflowService.getWorkflows(httpParams).pipe(
          switchMap((response: IGetManyResponse<IWorkflow>) => {
            return of(new ObjectActions.WorkflowDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
