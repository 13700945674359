import { IGetManyResponse, IGetOneResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { Action } from '@ngrx/store';
import { IGenericObject } from '../../../shared/model/interface/generic.model';
import { ITableQuery } from '../../../shared/model/interface/common-page.model';
import { IFormVersion, IFormTasksData, IFieldSetData } from './form-tasks.model';
import { HttpErrorResponse } from '@angular/common/http';
import { IFormTasksHistory } from '../../../view/my-tasks/form-tasks/form-tasks.component.model';
import { ITableHeader } from '../../../../constants.model';
import { IReview, IReviewResults } from '../my-tasks.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IWorkflow } from '../../settings/workflows/workflows.model';

export const START_FORM_TASKS_LOADING = '[Form Tasks] Start Form Tasks Loading';
export const FORM_TASKS_LOADED = '[Form Tasks] Form Tasks Loaded';
export const START_FORM_TASKS_BEFORE_RECORD_LOADING = '[Form Tasks] Start Form Tasks Before Record Loading';
export const FORM_TASKS_BEFORE_RECORD_LOADED = '[Form Tasks] Form Tasks Before Record Loaded';
export const START_FORM_TASKS_AFTER_RECORD_LOADING = '[Form Tasks] Start Form Tasks After Record Loading';
export const FORM_TASKS_AFTER_RECORD_LOADED = '[Form Tasks] Form Tasks After Record Loaded';
export const START_FORM_TASKS_BEFORE_FIELD_SET_LOADING = '[Form Tasks] Start Form Tasks Before FieldSet Loading';
export const FORM_TASKS_BEFORE_FIELD_SET_LOADED = '[Form Tasks] Form Tasks Before FieldSet Loaded';
export const START_FORM_TASKS_AFTER_FIELD_SET_LOADING = '[Form Tasks] Start Form Tasks After FieldSet Loading';
export const FORM_TASKS_AFTER_FIELD_SET_LOADED = '[Form Tasks] Form Tasks After FieldSet Loaded';
export const START_FORM_TASKS_HISTORY_LOADING = '[Form Tasks] Start Form Tasks History Loading';
export const FORM_TASKS_HISTORY_LOADED = '[Form Tasks] Form Tasks History Loaded';
export const START_FORM_TASKS_ACTIVE_VERSION_HISTORY_LOADING =
  '[Form Tasks] Start Form Tasks Active Version History Loading';
export const FORM_TASKS_ACTIVE_VERSION_HISTORY_LOADED = '[Form Tasks] Form Tasks Active Version History Loaded';
export const START_FORM_TASKS_CURRENT_VERSION_HISTORY_LOADING =
  '[Form Tasks] Start Form Tasks Current Version History Loading';
export const FORM_TASKS_CURRENT_VERSION_HISTORY_LOADED = '[Form Tasks] Form Tasks Current Version History Loaded';
export const START_ENTRY_APPROVE_LOADING = '[Form Tasks] Entry Approve Loading';
export const ENTRY_APPROVE_LOADED = '[Form Tasks] Entry Approve Loaded';
export const START_ENTRY_REJECT_LOADING = '[Form Tasks] Entry Reject Loading';
export const ENTRY_REJECT_LOADED = '[Form Tasks] Entry Reject Loaded';
export const START_ENTRY_REJECT_PERMANENT_LOADING = '[Form Tasks] Entry Reject Permanent Loading';
export const ENTRY_REJECT_PERMANENT_LOADED = '[Form Tasks] Entry Reject Permanent Loaded';
export const SET_TABLE_SETTINGS = '[Form Tasks] Form Tasks Set Table Settings';
export const START_WORKFLOW_DATA_LOADING = '[Form Tasks] Start Workflow Data Loading';
export const WORKFLOW_DATA_LOADED = '[Form Tasks] Workflow Data Loaded';
export const CLEAR_STATE = '[Form Tasks] Clear State';
export const FETCH_ERROR = '[Form Tasks] Fetch Error';

export class StartFormTasksLoading implements Action {
  readonly type = START_FORM_TASKS_LOADING;

  constructor(public tableQuery: IGenericObject<any> & ITableQuery, public reviewType: string) {}
}

export class FormTasksLoaded implements Action {
  readonly type = FORM_TASKS_LOADED;

  constructor(public payload: IGetManyResponse<IFormTasksData>) {}
}

export class StartFormTasksBeforeRecordLoading implements Action {
  readonly type = START_FORM_TASKS_BEFORE_RECORD_LOADING;

  constructor(public formId: number) {}
}

export class FormTasksBeforeRecordLoaded implements Action {
  readonly type = FORM_TASKS_BEFORE_RECORD_LOADED;

  constructor(public payload: IFormVersion) {}
}

export class StartFormTasksAfterRecordLoading implements Action {
  readonly type = START_FORM_TASKS_AFTER_RECORD_LOADING;

  constructor(public formId: number, public id: number, public reviewType: string) {}
}

export class FormTasksAfterRecordLoaded implements Action {
  readonly type = FORM_TASKS_AFTER_RECORD_LOADED;

  constructor(public payload: IFormVersion) {}
}

export class StartFormTasksBeforeFieldSetLoading implements Action {
  readonly type = START_FORM_TASKS_BEFORE_FIELD_SET_LOADING;

  constructor(public fieldSetVersionId: number) {}
}

export class FormTasksBeforeFieldSetLoaded implements Action {
  readonly type = FORM_TASKS_BEFORE_FIELD_SET_LOADED;

  constructor(public payload: IFieldSetData[]) {}
}

export class StartFormTasksAfterFieldSetLoading implements Action {
  readonly type = START_FORM_TASKS_AFTER_FIELD_SET_LOADING;

  constructor(public fieldSetVersionId: number) {}
}

export class FormTasksAfterFieldSetLoaded implements Action {
  readonly type = FORM_TASKS_AFTER_FIELD_SET_LOADED;

  constructor(public payload: IFieldSetData[]) {}
}

export class StartFormTasksHistoryLoading implements Action {
  readonly type = START_FORM_TASKS_HISTORY_LOADING;

  constructor(public id: number, public reviewType: string) {}
}

export class FormTasksHistoryLoaded implements Action {
  readonly type = FORM_TASKS_HISTORY_LOADED;

  constructor(public payload: IGetManyResponse<IFormTasksHistory>) {}
}

export class StartFormTasksActiveVersionHistoryLoading implements Action {
  readonly type = START_FORM_TASKS_ACTIVE_VERSION_HISTORY_LOADING;

  constructor(public id: number, public reviewType: string) {}
}

export class FormTasksActiveVersionHistoryLoaded implements Action {
  readonly type = FORM_TASKS_ACTIVE_VERSION_HISTORY_LOADED;

  constructor(public payload: IGetManyResponse<IFormTasksHistory>) {}
}

export class StartFormTasksCurrentVersionHistoryLoading implements Action {
  readonly type = START_FORM_TASKS_CURRENT_VERSION_HISTORY_LOADING;

  constructor(public id: number, public reviewType: string) {}
}

export class FormTasksCurrentVersionHistoryLoaded implements Action {
  readonly type = FORM_TASKS_CURRENT_VERSION_HISTORY_LOADED;

  constructor(public payload: IGetManyResponse<IFormTasksHistory>) {}
}

export class StartEntryApproveLoading implements Action {
  readonly type = START_ENTRY_APPROVE_LOADING;

  constructor(public reviewPayload: IReview, public issuer: IIssuer | null, public reviewType: string) {}
}

export class EntryApproveLoaded implements Action {
  readonly type = ENTRY_APPROVE_LOADED;

  constructor(public payload: IGetOneResponse<IReviewResults[]>) {}
}

export class StartEntryRejectLoading implements Action {
  readonly type = START_ENTRY_REJECT_LOADING;

  constructor(public reviewPayload: IReview, public issuer: IIssuer | null, public reviewType: string) {}
}

export class EntryRejectLoaded implements Action {
  readonly type = ENTRY_REJECT_LOADED;

  constructor(public payload: IGetOneResponse<IReviewResults[]>) {}
}

export class StartEntryRejectPermanentLoading implements Action {
  readonly type = START_ENTRY_REJECT_PERMANENT_LOADING;

  constructor(public reviewPayload: IReview, public issuer: IIssuer | null, public reviewType: string) {}
}

export class EntryRejectPermanentLoaded implements Action {
  readonly type = ENTRY_REJECT_PERMANENT_LOADED;

  constructor(public payload: IGetOneResponse<IReviewResults[]>) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: ITableHeader[], public setAsDefault: boolean) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: HttpErrorResponse) {}
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;

  constructor(public payload: IGenericObject<any>) {}
}

export class StartWorkflowDataLoading implements Action {
  readonly type = START_WORKFLOW_DATA_LOADING;
}

export class WorkflowDataLoaded implements Action {
  readonly type = WORKFLOW_DATA_LOADED;

  constructor(public payload: IGetManyResponse<IWorkflow>) {}
}

export type FormTasksActions =
  | StartFormTasksLoading
  | FormTasksLoaded
  | StartFormTasksBeforeRecordLoading
  | FormTasksBeforeRecordLoaded
  | StartFormTasksAfterRecordLoading
  | FormTasksAfterRecordLoaded
  | StartFormTasksBeforeFieldSetLoading
  | FormTasksBeforeFieldSetLoaded
  | StartFormTasksAfterFieldSetLoading
  | FormTasksAfterFieldSetLoaded
  | StartFormTasksHistoryLoading
  | FormTasksHistoryLoaded
  | StartFormTasksActiveVersionHistoryLoading
  | FormTasksActiveVersionHistoryLoaded
  | StartFormTasksCurrentVersionHistoryLoading
  | FormTasksCurrentVersionHistoryLoaded
  | StartEntryApproveLoading
  | EntryApproveLoaded
  | StartEntryRejectLoading
  | EntryRejectLoaded
  | StartEntryRejectPermanentLoading
  | EntryRejectPermanentLoaded
  | SetTableSettings
  | FetchError
  | ClearState
  | StartWorkflowDataLoading
  | WorkflowDataLoaded;
