import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../logbook.reducer';
import { FormEntriesService } from '../../../shared/service/reports/form-entries/form-entries.service';
import * as ObjectActions from '../../reports/form-entries/form-entries.actions';
import * as AppActions from '../../app/actions';
import { catchError, of, switchMap } from 'rxjs';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import { IGetManyResponse, IGetOneResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { IFormSubmissionData } from '../../my-tasks/logs-form-entries/logs-form-entries.model';
import { ILogsFormEntriesColumn } from '../../../view/my-tasks/logs-form-entries/logs-form-entries.component.model';
import { IFormEntriesData } from './form-entries.model';
import { emptyAction } from '../../../../constants';
import { IFormSubmissionUserAction } from '../../../../constants.model';

@Injectable()
export class FormEntriesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppState>,
    private readonly service: FormEntriesService,
  ) {}

  getReportData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_FORM_ENTRIES_LOADING),
      switchMap((payload: ObjectActions.StartFormEntriesLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const body: Record<string, string | number> = ServiceUtilities.prepareGenericBodyForRequest(payload.tableQuery);

        return this.service.getReportData(body).pipe(
          switchMap((response: IGetManyResponse<IFormEntriesData>) => {
            return of(
              new ObjectActions.FormEntriesLoaded(response),
              payload.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormEntriesRecord = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_FORM_ENTRIES_RECORD_LOADING),
      switchMap((payload: ObjectActions.StartFormEntriesRecordLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getFormEntriesRecord(payload.logbookId, payload.id).pipe(
          switchMap((response: IGetOneResponse<IFormSubmissionData>) => {
            return of(new ObjectActions.FormEntriesRecordLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormEntriesHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_FORM_ENTRIES_HISTORY_LOADING),
      switchMap((payload: ObjectActions.StartFormEntriesHistoryLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getFormEntriesHistory(payload.id).pipe(
          switchMap((response: IGetManyResponse<IFormSubmissionUserAction>) => {
            return of(new ObjectActions.FormEntriesHistoryLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getFormEntriesColumns = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_FORM_ENTRIES_COLUMN_LOADING),
      switchMap((payload: ObjectActions.StartFormEntriesColumnLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getFormEntriesColumns(payload.formIds, payload.parentLogbookId).pipe(
          switchMap((response: IGetManyResponse<ILogsFormEntriesColumn>) => {
            return of(
              new ObjectActions.FormEntriesColumnLoaded(response),
              payload.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadReportExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_REPORT_EXCEL),
      switchMap((objectData: ObjectActions.DownloadReportExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const body: Record<string, string | number> = ServiceUtilities.prepareGenericBodyForRequest(
          objectData.tableQuery,
        );
        this.service.downloadReportExcel(body, objectData.selectedColumns, objectData.formEntriesDynamicColumns);

        return emptyAction;
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  saveReportTableSettings = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SAVE_REPORT_TABLE_SETTINGS),
      switchMap((objectData: ObjectActions.SaveReportTableSettings) => {
        return this.service.saveReportTableSettings(objectData.reportId, objectData.meta).pipe(
          switchMap(() => {
            return of(new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  downloadReportExcelCompleted = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_REPORT_EXCEL_COMPLETED),
      switchMap(() => {
        this.store.dispatch(new AppActions.HideLoader());
        return emptyAction;
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
